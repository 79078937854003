import React, { FC } from "react"
import styled from "styled-components"
import MarkdownContent from "./MarkdownContent"
import Popover from "@material-ui/core/Popover"
import { Button } from "../ui/Button"

const Content = styled.div`
  padding: 1rem;
  fontsize: 0.65em;
  lineheight: 1.5em;
`
const Wrapper = styled.span`
  display: block;
  margin: 0.75rem 0;
`


const SimplePopover: React.FC = ({ children }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined

  return (
    <Wrapper>
      <Button
        type="secondaryOutline"
        title="more"
        size="sm"
        onClick={handleClick}
      />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Content>
          <MarkdownContent animate={false} content={children[0]} />
        </Content>
      </Popover>
    </Wrapper>
  )
}

export default SimplePopover
