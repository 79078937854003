import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { Image, FeaturedVideo } from "../../ts/interfaces"
import YouTube from "react-youtube"
import Modal from "./Modal"
import { devices } from "../config/breakpoints"

const Outter = styled.div`
  height: 100%;
  width: 100%;
  .videoContainer {
    height: 100%;
    width: 100%;
  }
`
const VideoInPlaceWrapper = styled.div`
  position: absolute;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 100;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 20px !important;
  overflow: hidden;
  z-index: 400;
`

const ImageWrapper = styled.div`
  transition: all 0.2s ease-in-out;

  & :hover {
    transform: scale(1.01);
  }
  z-index: 200;

  position: relative;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  .playButton {
    position: absolute;
    width: 40%;
    height: 40%;
    min-height: 8rem;
    min-width: 8rem;
    top: 30%;
    bottom: 0;
    left: 30%;
    right: 0;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    background-image: url(/source-imgs/play_button.png);
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 8rem;
    cursor: pointer;
    opacity: 0.7;
    transition: opacity 0.75s ease;
    :hover {
      opacity: 1;
    }

    @media ${devices.mobilePhone} {
      opacity: 1;
    }
  }
`

const FeatureImage = styled(props => <GatsbyImage {...props} />)`
  display: inline-block;
  margin: ${({ margin }) => (margin ? margin : "2rem 0")};
  width: 100%;
  max-width: 100vw;
  height: 100%;

  img {
    object-fit: cover !important;
    border-radius: ${({ borderRadius }) =>
      borderRadius ? borderRadius : "20px !important"};
  }
`
const isMobile =
  typeof window !== "undefined" &&
  window.screen.width < window.screen.height &&
  window.screen.width <= 420

const getFeature = ({
  setPlay,
  featuredImage,
  mobileImage,
  hasVideo,
  margin,
  borderRadius,
  objectPosition,
  videoId,
  hidePlayButton,
}: {
  setPlay: Function
  featuredImage?: Image
  mobileImage?: Image
  hasVideo?: boolean
  videoId?: string
  margin?: string
  borderRadius?: string
  objectPosition?: string
  hidePlayButton?: boolean
}) => {
  if (mobileImage && isMobile) {
    return (
      <ImageWrapper
        onClick={() => {
          setPlay(true)
        }}
      >
        <FeatureImage
          image={
            mobileImage.imageFile &&
            mobileImage.imageFile.childImageSharp.gatsbyImageData
          }
          objectPosition={objectPosition || "50% 100%"}
          alt={mobileImage.alternativeText}
          margin={margin}
          borderRadius={borderRadius}
          loading="eager"
        />

        {hasVideo && !hidePlayButton && (
          <div
            id={`video_${videoId}`}
            className="playButton"
            onClick={() => {
              setPlay(true)
            }}
          ></div>
        )}
      </ImageWrapper>
    )
  }
  if (featuredImage) {
    return (
      <ImageWrapper
        onClick={() => {
          setPlay(true)
        }}
      >
        <FeatureImage
          image={
            featuredImage.imageFile &&
            featuredImage.imageFile.childImageSharp.gatsbyImageData
          }
          objectPosition={objectPosition || "50% 100%"}
          alt={featuredImage.alternativeText}
          margin={margin}
          borderRadius={borderRadius}
          loading="eager"
        />
        {hasVideo && !hidePlayButton && (
          <div
            id={`video_${videoId}`}
            className="playButton"
            onClick={() => {
              setPlay(true)
            }}
          ></div>
        )}
      </ImageWrapper>
    )
  }
}

const FeatureImageVideo = (props: {
  featuredImage?: Image
  mobileImage?: Image
  featuredVideo?: FeaturedVideo
  margin?: string
  borderRadius?: string
  playInPlace?: boolean
  objectPosition?: string
  hidePlayButton?: boolean
}) => {
  const [modalOpen, setModalOpen] = useState(false)

  const [play, setPlay] = useState(false)
  const playInPlace = props.playInPlace || false

  let Feature: JSX.Element | undefined

  const videoId = props.featuredVideo?.youtubeId
  const videoMobileId = props.featuredVideo?.youtubeMobileId
  let setVideoId = videoId

  let videoFrameStyle = {}
  if (typeof window !== "undefined") {
    if ((!videoId && videoMobileId) || (videoMobileId && isMobile)) {
      setVideoId = videoMobileId
      //mobile video if availible
      videoFrameStyle = {
        marginTop: "5vw",
        width: "calc(100vw - 10vw)",
        height: "calc(100vh - 22vw)",
        maxHeight: "calc((100vw - 10vw) * 16/9)",
        maxWidth: "calc((100vh - 22vw) * 9/16)",
      }
    } else if (videoId) {
      videoFrameStyle = {
        width: "calc(100vw - 22vw)",
        height: "calc(100vh - 7vw)",
        maxHeight: "calc((100vw - 22vw) * 9/16)",
        maxWidth: "calc((100vh - 7vw) * 16/9)",
      }
    }
  }

  Feature = getFeature({
    setPlay,
    featuredImage: props.featuredImage,
    mobileImage: props.mobileImage,
    hasVideo: !!setVideoId,
    videoId: setVideoId,
    margin: props.margin,
    borderRadius: props.borderRadius,
    objectPosition: props.objectPosition,
    hidePlayButton: props.hidePlayButton,
  })

  useEffect(() => {
    if (!playInPlace) {
      setModalOpen(play)
    }
  }, [play])

  useEffect(() => {
    if (!modalOpen) {
      setPlay(false)
    }
  }, [modalOpen])

  return (
    <>
      {!playInPlace && videoId && (
        <Modal setIsOpen={setModalOpen} isOpen={modalOpen} darkMode={true}>
          <div style={videoFrameStyle}>
            <Outter>
              <YouTube
                videoId={setVideoId}
                className="classNameHere"
                containerClassName="videoContainer"
                opts={{
                  height: "100%",
                  width: "100%",
                  playerVars: {
                    // https://developers.google.com/youtube/player_parameters
                    autoplay: 1,
                    showinfo: 0,
                    rel: 0,
                    modestbranding: 1,
                  },
                }}
                onReady={event => {
                  event.target.playVideo()
                }}
                onEnd={() => {
                  setPlay(false)
                }}
              />
            </Outter>
          </div>
        </Modal>
      )}

      {Feature}
      {playInPlace && videoId && play && (
        <VideoInPlaceWrapper>
          <div style={{ width: "100%", height: "100%" }}>
            <Outter>
              <YouTube
                videoId={setVideoId}
                className="classNameHere"
                containerClassName="videoContainer"
                opts={{
                  height: "100%",
                  width: "100%",
                  playerVars: {
                    // https://developers.google.com/youtube/player_parameters
                    autoplay: 1,
                    showinfo: 0,
                    rel: 0,
                    modestbranding: 1,
                  },
                }}
                onReady={event => {
                  event.target.playVideo()
                }}
                onEnd={() => {
                  setPlay(false)
                }}
              />
            </Outter>
          </div>
        </VideoInPlaceWrapper>
      )}
    </>
  )
}
export default FeatureImageVideo
