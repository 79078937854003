import React from "react"
import Link from "../ui/link"
import Markdown from "markdown-to-jsx"
import styled from "styled-components"
import Quote from "./Quote"
import SimplePopover from "../ui/SimplePopover"
import { InlineMedia, InlineMediaProps } from "./InlineMedia"

const Content = styled(props => <Markdown {...props} />)`
  & ul {
    list-style-type: disc;
    text-align: left;
  }
  & sup {
    font-size: xx-small;
    vertical-align: super;
  }
  p img {
    width: 100%;
  }
`

const MarkdownContent = ({
  animate,
  content,
  inlineMedia,
}: {
  animate: boolean
  content: string
  inlineMedia?: InlineMediaProps[]
}) => {
  return (
    <Content
      data-aos={animate && "fade-up"}
      children={content}
      options={{
        forceBlock: true,
        overrides: {
          blockquote: {
            component: Quote,
            props: {
              isBlockQuote: true,
            },
          },
          a: {
            component: Link,
          },
          showmore: {
            component: SimplePopover,
          },
          image: {
            component: InlineMedia,
            props: {
              inlineMedia,
            },
          },
        },
      }}
    />
  )
}

export default MarkdownContent
