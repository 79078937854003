import React from "react"
import styled from "styled-components"
import AnimateHeight from "react-animate-height"

import { devices } from "../config/breakpoints"
import Icon from "../ui/Icon"

const QuoteMark = styled.div<{ isRight?: boolean; color?: string }>`
  font-family: var(--font-family-header);
  position: absolute;
  font-size: 5rem;
  font-weight: 900;
  left: ${({ isRight }) => (isRight ? "auto" : "0.25rem")};
  right: ${({ isRight }) => (isRight ? "0rem" : "auto")};
  top: ${({ isRight }) => (isRight ? "auto" : "0")};
  bottom: ${({ isRight }) => (isRight ? "-1.5rem" : "auto")};
  overflow: visible !important;
  ${({ color }) =>
    color
      ? `color: ${color};`
      : `
  background: linear-gradient(
    50deg,
    var(--color-teal) 5%,
    var(--color-purple) 95%
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;`}
  @media ${devices.mobile} {
    font-size: 4rem;
    top: ${({ isRight }) => (isRight ? "auto" : "-2rem")};
    bottom: ${({ isRight }) => (isRight ? "-1.75rem" : "auto")};
  }
`

const QuoteWrap = styled.div<{
  color?: string
  fontStyle?: string
  margin?: string
  expanded: boolean
}>`
  position: relative;
  display: inline-block;
  .innerQuote {
    color: ${({ color }) => color || "var(--color-teal)"};
    font-style: ${({ fontStyle }) => fontStyle || "italic"};
    font-weight: 500;
    font-size: 1.1875rem;
    line-height: 2rem;
    letter-spacing: 0;

    &.truncate {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
    @media ${devices.mobilePhone} {
      font-size: 1rem;
      line-height: 1.45rem;
    }
  }

  max-width: 100%;

  margin: ${({ margin }) => margin || "0 3.5rem"};
  padding: 1rem 4rem 2rem 4rem;
  @media ${devices.mobile} {
    margin-left: 0rem;
    margin-right: 0rem;
    margin-top: 5rem;
    padding: 1rem 0rem 2rem 0.5rem;
  }
  @media ${devices.mobilePhone} {
    margin-top: 2rem;
  }
`

const MoreLessContainer = styled.div`
  overflow: hidden;
  position: relative;
  transition: height 1s;
  height: 100%;
`

const ShowMoreLess = styled.div`
  margin: 0.5rem;
  &.less {
    position: absolute;
    bottom: 0rem;
    width: 100%;
    z-index: 500;
  }
  font-size: 1.5rem;
  .moreDesc {
    transition: color, font-weight, font-size 0.75s ease;
    font-size: 1.1875rem;
    line-height: 1.2rem;
    text-transform: uppercase;
    font-family: var(--font-family-header);
    font-weight: 700;
  }

  :hover {
    .moreDesc {
      color: var(--color-purple);
      font-weight: 800;
      font-size: 1.25rem;
    }
  }
`

const Quote = ({
  children,
  color,
  fontStyle,
  margin,
  expanded,
  isBlockQuote = false,
  truncate,
}: {
  children: any
  color?: string
  fontStyle?: string
  margin?: string
  expanded: boolean
  isBlockQuote?: boolean
  truncate: boolean
}) => {
  const [isAnimating, setIsAnimating] = React.useState<boolean>(false)

  const checkRef = React.useRef(null)

  return (
    <>
      <QuoteWrap
        color={color}
        fontStyle={fontStyle}
        margin={margin}
        expanded={expanded}
      >
        <QuoteMark color={color}>&ldquo;</QuoteMark>
        {isBlockQuote ? (
          <div className="innerQuote">{children}</div>
        ) : truncate ? (
          <AnimateHeight
            style={{
              position: "relative",
              marginTop: "1em",
            }}
            duration={1500}
            height={expanded ? "auto" : 100}
            onAnimationStart={() => setIsAnimating(!isAnimating)}
            onAnimationEnd={() => setIsAnimating(!isAnimating)}
          >
            <MoreLessContainer>
              <ShowMoreLess ref={checkRef}>
                <div
                  className={`innerQuote ${
                    !expanded && !isAnimating && truncate && "truncate"
                  }`}
                >
                  {children}
                </div>
              </ShowMoreLess>
            </MoreLessContainer>
          </AnimateHeight>
        ) : (
          <div className="innerQuote">{children}</div>
        )}
        <QuoteMark isRight={true} color={color}>
          &rdquo;
        </QuoteMark>
      </QuoteWrap>
    </>
  )
}

export default Quote
