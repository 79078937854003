import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { Image, FeaturedVideo } from "../../ts/interfaces"
import FeatureImageVideo from "./FeatureImageVideo"

export interface InlineMediaProps {
  referenceName: string
  rounded: boolean
  align: "left" | "right"
  width: "full" | "twoThirds" | "half" | "oneThird"
  youtubeId?: string
  youtubeMobileId?: string
  image: Image
  mobileImage?: Image
}

const InlineImageContainer = styled((props: InlineMediaProps) => (
  <div {...props} />
))`
  display: inline-block;
  ${({ align }) =>
    align === "left"
      ? `
float: left;
margin: 0.5rem 2rem 0.5rem 0;
`
      : `
float: right;
margin: 0.5rem 0rem 0.5rem 2rem;
`}
  ${({ width }) =>
    width === "full"
      ? `
margin-left: 0;
margin-right: 0;
`
      : ""}

  width: ${({ width }) =>
    width === "full"
      ? "100%"
      : width === "half"
      ? "50%"
      : width === "twoThirds"
      ? "66.67%"
      : "33.3%"};
  cursor: pointer;
`

export const InlineMedia = (props: {
  src: string
  inlineMedia: InlineMediaProps[]
}) => {
  const imgProps = props.inlineMedia.find(
    m => m.referenceName.toLowerCase() === props.src.toLowerCase()
  )
  if (!imgProps) {
    return null
  }

  return (
    <InlineImageContainer {...imgProps}>
      <FeatureImageVideo
        featuredImage={imgProps.image}
        mobileImage={imgProps.mobileImage}
        featuredVideo={{
          youtubeId: imgProps.youtubeId,
          youtubeMobileId: imgProps.youtubeMobileId,
        }}
        borderRadius={imgProps.rounded ? undefined : "0"}
        objectPosition="50% 50%"
        margin="0"
        hidePlayButton={true}
      />
    </InlineImageContainer>
  )
}
